import * as React from "react";
import * as icons from "./icons";
import { useSelector } from "react-redux";
import { DashboardMenuItem, MenuItemLink } from "react-admin";
import { useAdminContext } from "@lolocompany/react-admin-lolo";
import { Collapse, List } from '@material-ui/core';

const Menu = ({ onMenuClick, dense }) => {
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const { selectedAccount } = useAdminContext();

  if (!selectedAccount) return null;

  return (
    <div style={{ marginTop: 10 }}>
      {" "}
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      {selectedAccount.isRoot ? (
        <>
          <MenuItemLink
            to={`/accounts`}
            primaryText="Accounts"
            leftIcon={<icons.AccountIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/csps`}
            primaryText="CSPs"
            leftIcon={<icons.CSPIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/apns`}
            primaryText="APNs"
            leftIcon={<icons.APNIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/apn-networks`}
            primaryText="APN Networks"
            leftIcon={<icons.APNNetworkIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/subnets`}
            primaryText="Subnets"
            leftIcon={<icons.SubnetIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/egress-gateways`}
            primaryText="Egress Gateways"
            leftIcon={<icons.GatewayIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/radius-clients`}
            primaryText="RADIUS Clients"
            leftIcon={<icons.ThreeSixtyIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/smscs`}
            primaryText="SMSC Accounts"
            leftIcon={<icons.SMSCIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/smsc-groups`}
            primaryText="SMSC Groups"
            leftIcon={<icons.SMSCGroupIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/vrfs`}
            primaryText="VRFs"
            leftIcon={<icons.VRFIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/report/billing`}
            primaryText="Monthly Billing"
            leftIcon={<icons.MonthlyBillingReportIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </>
      ) : (
        <>
          <MenuItemLink
            to={`/device-groups`}
            primaryText="Device Groups"
            leftIcon={<icons.DeviceGroupIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/devices`}
            primaryText="Devices"
            leftIcon={<icons.DeviceIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/device-ids`}
            primaryText="Device Identities"
            leftIcon={<icons.DeviceIdIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/apns`}
            primaryText="APNs"
            leftIcon={<icons.APNIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/apn-networks`}
            primaryText="APN Networks"
            leftIcon={<icons.APNNetworkIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/apn-groups`}
            primaryText="APN Groups"
            leftIcon={<icons.APNGroupIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/dns-records`}
            primaryText="DNS Records"
            leftIcon={<icons.DNSRecordIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/acls`}
            primaryText="Access Lists"
            leftIcon={<icons.ACLIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/aggregations`}
            primaryText="Aggregations"
            leftIcon={<icons.AggregationIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/automations`}
            primaryText="Automations"
            leftIcon={<icons.AutomationIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/geofences`}
            primaryText="Geofences"
            leftIcon={<icons.GeofenceIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/geofence-groups`}
            primaryText="Geofence Groups"
            leftIcon={<icons.GeofenceGroupIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/gateways`}
            primaryText="Gateways"
            leftIcon={<icons.GatewayIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/vpn-tunnels`}
            primaryText="VPN Tunnels"
            leftIcon={<icons.VPNIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/forwardings`}
            primaryText="Forwarding Rules"
            leftIcon={<icons.ForwardingIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/subnets`}
            primaryText="Subnets"
            leftIcon={<icons.SubnetIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/sessions`}
            primaryText="Sessions"
            leftIcon={<icons.SessionIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/smscs`}
            primaryText="SMSC Accounts"
            leftIcon={<icons.SMSCIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/smsc-groups`}
            primaryText="SMSC Groups"
            leftIcon={<icons.SMSCGroupIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/smsc-numbering-plans`}
            primaryText="SMSC Numbering Plan"
            leftIcon={<icons.SMSCNumberingPlanIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <ReportMenu
            open={open}
          />
        </>
      )}
    </div>
  );
};

const ReportMenu = props => {
  const { open: sidebarIsOpen } = props;
  const [open, setOpen] = React.useState(false);

  const onMenuClick = () => {
    setOpen(!open);
  }

  return (
    <>
      <MenuItemLink to={'#'}
        primaryText='Reports'
        leftIcon={<icons.ReportIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={sidebarIsOpen}
        dense={false}
      />

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <MenuItemLink
            style={{ marginLeft: 40 }}
            to="/accounting"
            primaryText="Accounting"
            onClick={() => {}}
            dense={true}
          />
        </List>
      </Collapse>
    </>
  );
}

export default Menu;
