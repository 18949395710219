import { dataProvider as initDataProvider } from '@lolocompany/react-admin-lolo';
import { stringify } from 'query-string';

const gcpaDataProvider = apiUrl => {
  const dataProvider = initDataProvider(apiUrl);

  const origList = dataProvider.getList;

  dataProvider.getList = async function (resource, params, queryOpts) {
    if (resource === 'accounting') {
      return listAccounting(this.sendRequest, params, queryOpts);
    
    } else {
      return origList.call(this, resource, params, queryOpts);
    }
  };

  return dataProvider;
};

const listAccounting = async (sendRequest, params, queryOpts) => {
  const {
    page = 1,
    perPage = 10
  } = params.pagination || {};

  const {
    field = 'time',
    order = 'ASC'
  } = params.sort || {};

  const query = {
    limit: perPage,
    offset: (page - 1) * perPage,
    sort: `${field} ${order.toLowerCase()}`,
    ...buildQs(params.filter),
    ...queryOpts
  };

  const { data: events } = await sendRequest(
    `/accounting?${stringify(query)}`
  );

  await addDeviceNames(events, sendRequest);

  return {
    data: events,
    total: 1000
  }
};

const addDeviceNames = async (events, sendRequest) => {
  const deviceIds = new Set();
  
  events.forEach((event, i) => {
    deviceIds.add(event.deviceId);  
    event.id = i;
  });

  const { data: { devices }} = await sendRequest(
    '/devices?limit=100&pick[]=id&pick[]=name&' +
    Array.from(deviceIds).map(id => 'q[id]=' + id).join('&')
  );

  const deviceNameById = devices.reduce((memo, device) => {
    memo[device.id] = device.name;
    return memo;
  }, {});

  events.forEach(event => {
    event.deviceName = deviceNameById[event.deviceId];
  });
}

const buildQs = (filter = {}) => {
  return Object.entries(filter).reduce((memo, [k, v]) => {
    memo[`q[${k}]`] = v;
    return memo;
  }, {});
};

export default gcpaDataProvider