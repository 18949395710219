import React, { useEffect, useState } from "react";
import { Typography, Box, Button, ButtonGroup } from "@material-ui/core";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import VisTimeline from "react-vis-timeline-2";
import { formatBytes } from "../../dashboard/utils";
import prettyTime from "pretty-time";
import { useHistory } from 'react-router-dom';

import "./Timeline.css";

const MS_24H = 60 * 60 * 24 * 1000;
const NS_1S = 1000000000;

const tlOptions = {
  width: "100%",
  minHeight: "200px",
  stack: false,
  orientation: {
    axis: "top",
    item: "top",
  },
  tooltip: {
    delay: 10,
    followMouse: true,
  },
  zoomMin: 10000,
  zoomFriction: 50,
  selectable: false,
  groupTemplate: function (group) {
    return group
      ? '<a href="#/apns/' + group.id + '">' + group.content + "</a>"
      : null;
  },
  cluster: {
    maxItems: 3,
    fitOnDoubleClick: true,
  },
  selectable: true
};

const Timeline = ({ apns = {}, acctSessions = {} }) => {
  const [initTlFit, setInitTlFit] = useState(false);
  const [groups, setGroups] = useState([]);
  const [items, setItems] = useState([]);
  const [maxDate, setMaxDate] = useState(new Date().getTime());
  const timelineRef = React.useRef();
  const history = useHistory();

  useEffect(() => {
    if (Object.keys(acctSessions).length && Object.keys(apns).length) {
      const groups = getTlGroups(acctSessions, apns);
      const items = getTlItems(acctSessions);
      setGroups(groups);
      setItems(items);
    }
  }, [apns, acctSessions]);

  useEffect(() => {
    if (groups.length && items.length) {
      timelineRef.current.timeline.setData({
        groups,
        items,
      });

      const { minMs, maxMs } = items.reduce(
        (memo, item) => {
          if (!memo.minMs || item.start < memo.minMs) memo.minMs = item.start;
          if (!memo.maxMs || item.end > memo.maxMs) memo.maxMs = item.end;
          return memo;
        },
        { minMs: undefined, maxMs: undefined }
      );
      setMaxDate(maxMs);
      timelineRef.current.timeline.setOptions({
        ...tlOptions,
        min: minMs - MS_24H,
        max: maxMs + MS_24H,
      });

      if (!initTlFit) {
        timelineRef.current.timeline.fit();
        setInitTlFit(true);
      }
    }
  }, [groups, items, initTlFit]);

  const rangeButton = (days = 1) => (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={() =>
        timelineRef.current.timeline.setWindow(
          maxDate - days * MS_24H,
          maxDate + MS_24H
        )
      }
    >
      {days > 1 ? days : "24"} {days > 1 ? "d" : "h"}
    </Button>
  );

  return groups.length && items.length ? (
    <Box marginTop={2}>
      <Typography variant="h6">Timeline</Typography>
      <Box marginTop={2}>
        <VisTimeline
          ref={timelineRef}
          clickHandler={({ item: acctSessionId }) => {
            if (!acctSessionId || acctSessionId.indexOf('-') >= 0) return;

            const fromDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString();
            const toDate = new Date().toISOString()

            const url = '/accounting' +
              '?displayedFilters={"acctSessionId":true}' +
              '&filter=' + JSON.stringify({
                acctSessionId,
                fromDate,
                toDate
              });

            history.push(url);
          }}
          doubleClickHandler={({ item }) =>
            timelineRef.current.timeline.focus(item)
          }
        />
        <Box display={"flex"} justifyContent={"space-between"} marginTop={2}>
          <Box>
            <ButtonGroup>
              {rangeButton(1)}
              {rangeButton(7)}
              {rangeButton(30)}
              {rangeButton(90)}
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => timelineRef.current.timeline.fit()}
              >
                Fit all
              </Button>
            </ButtonGroup>
          </Box>
          <Box>
            <ButtonGroup>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => timelineRef.current.timeline.zoomOut(1)}
              >
                <ZoomOutIcon />
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => timelineRef.current.timeline.zoomIn(1)}
              >
                <ZoomInIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : null;
};

const getTlGroups = (acctSessions, apns) => {
  const ids = [];
  return Object.keys(acctSessions).reduce((memo, sessionId) => {
    const id = acctSessions[sessionId].apnId;
    if (!id || ids.includes(id)) return memo;
    ids.push(id);
    memo.push({ id, content: apns[id] });
    return memo;
  }, []);
};
const getTlItems = acctSessions => {
  return Object.keys(acctSessions).reduce((memo, sessionId) => {
    const session = acctSessions[sessionId];
    const {
      secs = 0,
      bytes,
      bytesIn = 0,
      bytesOut = 0,
      ts,
      tc,
      apnId,
    } = session;

    if (secs === undefined || !apnId) return memo;

    const active = !tc;
    const start = active ? ts : ts - secs * 1000;
    const end = active ? new Date().getTime() : ts;

    const title = [
      `Started: ${new Date(start).toLocaleDateString()} ${new Date(
        start
      ).toLocaleTimeString()}`,
    ];

    if (typeof bytesIn === "undefined") {
      title.push("Upload + Download: " + formatBytes(bytes));

    } else {
      title.push(
        `UL: ${formatBytes(bytesIn)}, DL: ${formatBytes(bytesOut)}`
      );
    }

    if (tc) {
      title.push(
        `Session ended after ${prettyTime(secs * NS_1S, "s")} due to ${tc}`
      );
    } else {
      const secsOnline = (new Date().getTime() - start) / 1000;
      title.push(`Online for ${prettyTime(secsOnline * NS_1S, "s")}`);
    }

    memo.push({
      group: apnId,
      id: sessionId,
      start,
      end,
      title: title.join("<br/>"),
      active,
      className: active ? "active" : undefined,
      type: "range",
    });
    return memo;
  }, []);
};

export default Timeline;
